import React, { useState, useMemo, useEffect } from 'react';
import UserForm from '../UserForm/UserForm'

import { isValidPhoneNumber } from 'react-phone-number-input';
// import countryList from 'react-select-country-list'
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { EditUser } from "../../store/actions/users";
import { axiosInstance } from "../../network/axios";
import countryListOptions from '../../json/countries.json'

export default function EditProfile() {
    const history = useHistory();
    const dispatch = useDispatch();
    const params = useParams();
    let auth  = useSelector(state => state.auth);
    let user = useSelector((state) => state.users.user);
    const [profile, setProfile] = useState(false);
    const [disabledBtn, setdisabledBtn] = useState(true);

    const emailPattern= new RegExp("^[^\\s@]+@([^\\s@.,]+\\.)+[^\\s@.,]{2,}$")
    const maxDate = new Date().toISOString().split("T")[0];
    const minDate = '1900-01-01';
    const namePattern=new RegExp("^[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF]+( [\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF]+)*$")
    // const namePattern=new RegExp("^[a-z]*$")
    // const passPattern=new  RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})")

    // const countryListOptions = useMemo(() => countryList().getData(), [])

    const QuranListOptions = [
    { value: 'lessThanFive', label: 'أقل من خمسة أجزاء' },
    { value: 'moreThanFive', label: 'خمسة أجزاء فأكثر' },
    { value: 'full', label: 'القرآن الكريم كاملاً' }
    ];

    const AcademicListOptions = [
    { value: 'HighSchool', label: 'ثانوية عامة' },
    { value: 'Bachelor', label: 'جامعي (بكالوريوس)' },
    { value: 'Master', label: 'ماجستير' },
    { value: 'PhD', label: 'دكتوراه' },
    ];

    const [form, setForm] = useState({
        inputUser: "",
        inputBirthdate: "",
        inputGender: "",
        inputPhone: "",
        inputNationality: "",
        inputLive: "",
        inputQuran: "",
        inputAcademic: "",
        inputEmail: "",
        inputUsername:"",
        // inputPassword: "",
        // inputPasswordConfirm: "",
    });

    const [formErrors, setFormError] = useState({
        inputUserErr: null,
        inputBirthdateErr: null,
        inputGenderErr: null,
        inputPhoneErr: null,
        inputNationalityErr: null,
        inputLiveErr: null,
        inputQuranErr: null,
        inputAcademicErr: null,
        inputEmailErr: null,
        inputUsernameErr: null,
        // inputPasswordErr: null,
        // inputPasswordConfirmErr: null,
    });

    useEffect(() => {}, [form]);

    useEffect(()=>{
        if(auth === null){
            history.push('/');
        }else{
            handleGetUser()
        }
    },[auth,params.id])

      const handleGetUser = async ()=>{
        if(auth !== null){
            try{
                let res = await axiosInstance.get(`/user/user`, {
                    headers: {
                    authorization:auth.token,
                    },
                })
                dispatch({
                  type: "GET_USER",
                  payload: res.data,
                });
                if (res.data && res.data.Active && res.data.ActivatedEmail && res.data.Approval && !res.data.SoftDelete){
                    setProfile(true)
                    setForm({
                        inputUser: res.data.Name || "",
                        inputBirthdate: res.data.Birthdate || "",
                        inputGender: res.data.Gender || "",
                        inputPhone: res.data.Phone || "",
                        inputNationality: res.data.Nationality || "",
                        inputLive: res.data.Live || "",
                        inputQuran: res.data.Quran || "",
                        inputAcademic: res.data.Academic || "",
                        inputEmail: res.data.Email || "",
                        inputUsername: res.data.Username || "",
                        // inputPassword: "",
                        // inputPasswordConfirm: "",
                      })
                }
            } catch (err) {console.log(err)}
        }else{
            setProfile(false)
        }
    }

    const handleArabicKeyPress = (event) => {
        if (
            !(
                /^[\u0600-\u06FF\s]$/.test(event.key) || 
                event.key === 'Backspace' ||
                event.key === 'ArrowLeft' ||
                event.key === 'ArrowRight' ||
                event.key === 'Tab'
            )
        ) {
            event.preventDefault();
        }
    };

    const handleEnglishKeyPress = (event) => {
        if (
            !(
                /^[a-zA-Z0-9!@#$%^&*]$/.test(event.key) || 
                event.key === 'Backspace' ||
                event.key === 'ArrowLeft' ||
                event.key === 'ArrowRight' ||
                event.key === 'Tab'
            )
        ) {
            event.preventDefault();
        }
    };
    
    const handelFormChange = (e) => {
        if (e.target.name === "inputUser") {
          setdisabledBtn(false)
          setForm({
              ...form,
              inputUser: e.target.value,
          });
          setFormError({
              ...formErrors,
              inputUserErr:
                  e.target.value.length === 0
                      ? "* هذا الحقل مطلوب"
                      : /^\s|\s$/.test(e.target.value)
                      ? "* يجب ان لا يبدأ او ينتهى الاسم بمسافة"
                      : /\s{2,}/.test(e.target.value)
                      ? "* يجب ان لا يحتوى الاسم على مسافتين"
                      : !namePattern.test(e.target.value)
                      ? "* يجب كتابة الاسم باللغة العربية ولا يحتوى على اى رموز او ارقام"
                      : null,
          });
      } 
      else if (e.target.name === "inputBirthdate") {
          setdisabledBtn(false)
          setForm({
              ...form,
              inputBirthdate: e.target.value,
          });
          setFormError({
              ...formErrors,
              inputBirthdateErr:
                  e.target.value.length === 0
                      ? "* هذا الحقل مطلوب"
                      : null,
          });
      } 
      else if (e.target.name === "inputGender") {
          setdisabledBtn(false)
          setForm({
              ...form,
              inputGender: e.target.value,
          });
          setFormError({
              ...formErrors,
              inputGenderErr:
                  e.target.value.length === 0
                      ? "* هذا الحقل مطلوب"
                      : null,
          });
      }
      else if (e.target.name === "inputUsername") {
        setdisabledBtn(false)
        setForm({
            ...form,
            inputUsername: e.target.value,
        });
        setFormError({
            ...formErrors,
            inputUsernameErr:
                e.target.value.length === 0
                    ? "* هذا الحقل مطلوب"
                    : null,
        });
    }
    // else if (e.target.name === "inputPassword") {
    //   setdisabledBtn(false)
    //   setForm({
    //       ...form,
    //       inputPassword: e.target.value,
    //   });
    //   setFormError({
    //       ...formErrors,
    //       inputPasswordErr:
    //           e.target.value.length === 0
    //               ? "* هذا الحقل مطلوب"
    //               : null,
    //   });
    // }
    // else if (e.target.name === "inputPasswordConfirm") {
    //   setdisabledBtn(false)
    //   setForm({
    //       ...form,
    //       inputPasswordConfirm: e.target.value,
    //   });
    //   setFormError({
    //       ...formErrors,
    //       inputPasswordConfirmErr:
    //           e.target.value.length === 0
    //               ? "* هذا الحقل مطلوب"
    //               : e.target.value !== form.inputPassword
    //               ? "كلمة المرور غير متطابقة"
    //               : null,
    //   });
    // }
      else if (e.target.name === "ReginputEmail") {
        setdisabledBtn(false)
        setForm({
            ...form,
            inputEmail: e.target.value,
        });
        setFormError({
            ...formErrors,
            inputEmailErr:
                e.target.value.length === 0
                    ? "* هذا الحقل مطلوب"
                    : !emailPattern.test(e.target.value)
                    ? "* الايميل غير صحيح (exapmle@ayaat.com)"
                    : null,
        });
      }
    };

    const handlePhoneChange =(phone)=>{
        setdisabledBtn(false)
        setForm({
        ...form,
        inputPhone: phone,
        });
        setFormError({
            ...formErrors,
            inputPhoneErr:
                    phone === undefined
                    ? "* هذا الحقل مطلوب"
                    : !isValidPhoneNumber(phone)
                    ?  "* هذا الرقم غير مطابق لكود البلد"
                    : null,
        });
    }

    const handleNationalityChange =(country)=>{
        setdisabledBtn(false)
        setForm({
        ...form,
        inputNationality: country.value,
        });
        setFormError({
            ...formErrors,
            inputNationalityErr:
                    country.value === undefined
                    ? "* هذا الحقل مطلوب"
                    : null,
        });
    }

    const handleLiveChange =(country)=>{
        setdisabledBtn(false)
        setForm({
        ...form,
        inputLive: country.value,
        });
        setFormError({
            ...formErrors,
            inputLiveErr:
                    country.value === undefined
                    ? "* هذا الحقل مطلوب"
                    : null,
        });
    }
    
    const handleQuranChange =(level)=>{
        setdisabledBtn(false)
        setForm({
        ...form,
        inputQuran: level.value,
        });
        setFormError({
            ...formErrors,
            inputQuranErr:
                    level.value === undefined
                    ? "* هذا الحقل مطلوب"
                    : null,
        });
    }
    
    const handleAcademicChange =(level)=>{
        setdisabledBtn(false)
        setForm({
        ...form,
        inputAcademic: level.value,
        });
        setFormError({
            ...formErrors,
            inputAcademicErr:
                    level.value === undefined
                    ? "* هذا الحقل مطلوب"
                    : null,
        });
    }
    
    const validationForm = ()=>{
        setFormError({
        ...formErrors,
        inputUserErr: form.inputUser === "" ?"* هذا الحقل مطلوب" :null,
        inputBirthdateErr:  form.inputBirthdate === ""? "* هذا الحقل مطلوب":null,
        inputGenderErr:  form.inputGender === ""? "* هذا الحقل مطلوب":null,
        inputPhoneErr:  form.inputPhone === ""? "* هذا الحقل مطلوب":null,
        inputNationalityErr:  form.inputNationality === ""? "* هذا الحقل مطلوب":null,
        inputLiveErr:  form.inputLive === ""? "* هذا الحقل مطلوب":null,
        inputQuranErr:  form.inputQuran === ""? "* هذا الحقل مطلوب":null,
        inputAcademicErr:  form.inputAcademic === ""? "* هذا الحقل مطلوب":null,
        inputEmailErr:  form.inputEmail === ""? "* هذا الحقل مطلوب":null,
        inputUsernameErr:  form.inputUsername === ""? "* هذا الحقل مطلوب":null,
        // inputPasswordErr:  form.inputPassword === ""? "* هذا الحقل مطلوب":null,
        // inputPasswordConfirmErr:  form.inputPasswordConfirm === ""? "* هذا الحقل مطلوب":null,
        });
    }
  
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setdisabledBtn(true)
        if(form.inputUser === "" || form.inputBirthdate === "" || form.inputGender === "" || form.inputPhone === "" || form.inputNationality === "" || form.inputLive === "" || form.inputQuran === "" || form.inputAcademic === "" || form.inputEmail === "" || form.inputUsername === "" /*|| form.inputPassword === "" ||  form.inputPasswordConfirm === ""*/){
        validationForm()
        return
        }
        try{
            await EditUser({
                Name : form.inputUser,
                Birthdate: form.inputBirthdate,
                Gender: form.inputGender,
                Phone : form.inputPhone,
                Nationality : form.inputNationality,
                Live : form.inputLive,
                Quran : form.inputQuran,
                Academic : form.inputAcademic,
                Email : user.Email,
                Username : form.inputUsername,
                Password: user.Password,
                Approval : user.Approval,
                ApprovalBy:user.ApprovalBy,
                ActivatedEmail : user.ActivatedEmail,
                Active : user.Active,
                SoftDelete:user.SoftDelete,
                DeletedBy:user.DeletedBy
            },auth.token)
            setForm({
                inputUser: "",
                inputBirthdate: "",
                inputGender: "",
                inputPhone: "",
                inputNationality: "",
                inputLive: "",
                inputQuran: "",
                inputAcademic: "",
                inputEmail: "",
                inputUsername:"",
                // inputPassword: "",
                // inputPasswordConfirm: "",
            })
            setFormError({
                inputUserErr: null,
                inputBirthdateErr: null,
                inputGenderErr: null,
                inputPhoneErr: null,
                inputNationalityErr: null,
                inputLiveErr: null,
                inputQuranErr: null,
                inputAcademicErr: null,
                inputEmailErr: null,
                inputUsernameErr: null,
                // inputPasswordErr: null,
                // inputPasswordConfirmErr: null,
            })
            await toast.success("تم تعديل بياناتك الشخصية");
            window.location.reload()
        } catch (err) {
            console.log("err ==> ",err)
            if(err.response.data === "UN_AUTH"){
                toast.error("يجب تسجيل الدخول");
            } else if(err.response.data === "USER_EXIST"){
                toast.error("اسم المستخدم او الايميل موجود بالفعل");
            }
        }
    };

  return (
    <>
        <section className='w-50 mx-auto'>
            {profile && <UserForm
                countryListOptions={countryListOptions}
                QuranListOptions={QuranListOptions}
                AcademicListOptions={AcademicListOptions}
                maxDate={maxDate}
                minDate={minDate}
                form={form}
                formErrors={formErrors}
                handelFormChange={handelFormChange}
                handlePhoneChange={handlePhoneChange}
                handleNationalityChange={handleNationalityChange}
                handleLiveChange={handleLiveChange}
                handleQuranChange={handleQuranChange}
                handleAcademicChange={handleAcademicChange}
                handleFormSubmit={handleFormSubmit}
                disabledBtn={disabledBtn}
                handleArabicKeyPress={handleArabicKeyPress}
                handleEnglishKeyPress={handleEnglishKeyPress}
                action={'editUser'}
            />}
        </section>
    </>   
  )
}
