import { axiosInstance } from "../../network/axios";

// let authToken = ""
// if (localStorage.getItem("auth")) {
//   authToken = JSON.parse(localStorage.getItem("auth"));
//   console.log(authToken.token);
// }

export const userLogin = async (auth) => await axiosInstance.post("/user/login", auth);

export const userRegister = async (auth) => await axiosInstance.post("/user/register", auth);
