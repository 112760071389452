const INITIAL_STATE = {
    user:{}
}

export default function users(state = INITIAL_STATE,action) {
    switch (action.type) {
      case "GET_USER":
        return {
            ...state,
            user : action.payload
        };
      default:
        return state;
    }
  }