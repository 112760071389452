import { combineReducers } from "redux";
import users from "./users";
import auth from "./auth";
import programmes from "./programmes";
// import courses from "./courses";
// import admins from "./admins";
// import news from "./news";

export default combineReducers({
    users:users,
    auth:auth,
    programmes:programmes,
    // courses:courses,
    // admins:admins,
    // news:news
});