import React, { useEffect, useState } from 'react'
import "./Programmes.scss"

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import HeaderSection from '../../Components/HeaderSection/HeaderSection'
import star from '../../assets/images/star.webp'
import ContactSection from '../../Components/ContactSection/ContactSection'
import { axiosInstance } from "../../network/axios";

export default function Programmes({handleShow}) {
  const dispatch = useDispatch();
  const params = useParams();
  let auth  = useSelector(state => state.auth);
  const [profile, setProfile] = useState(false);
  const [userProgrammeStatus, setUserProgrammeStatus] = useState();
  let programme = useSelector(state => state.programmes.programme);

  const handleCheckUser = async ()=>{
    if(auth !== null){
        try{
            let res = await axiosInstance.get(`/user/user`, {
                headers: {
                authorization:auth.token,
                },
            })
            if (res.data && res.data.Active && res.data.ActivatedEmail && res.data.Approval && !res.data.SoftDelete){
                dispatch({
                type: "GET_USER",
                payload: res.data,
                });
                setProfile(true)
            }
        } catch (err) {console.log(err)}
    }else{
        setProfile(false)
    }
}

const handleGetProgramme = async()=>{
  try{
    let res = await axiosInstance.get(`/programme/programmes/${params.id}`)
    if(res.data){
        dispatch({
            type: "GET_PROGRAMME",
            payload: res.data,
        });
    }
  } catch (err) {console.log(err)}
}

const handleCheckUserProgrammeStatus = async()=>{
  if(auth !== null){
    try{
      let res = await axiosInstance.get(`/user/usersprogrammes`,{
          headers: {
          authorization:auth.token,
        }})
      if(res.data){
        setUserProgrammeStatus(res.data)
      }else{
        setUserProgrammeStatus("unsubscribed")
      }
    } catch (err) {console.log(err)}
  }
}

const handleUserProgrammeSubscribed = async()=>{
  if(auth !== null){
    try{
      await axiosInstance.post(`/user/usersprogrammes`,{
        ProgrammeId:params.id
      },{
          headers: {
          authorization:auth.token,
        }})
        window.location.reload(false);
    } catch (err) {console.log(err)}
  }
}

useEffect(()=>{
  handleGetProgramme()
  handleCheckUser()
  handleCheckUserProgrammeStatus()
},[])

  return (
    <section className='programmes' id='programmes'>
      <HeaderSection image={`http://localhost:8000/${programme.IntroImage}`}/>
      <div className='programmes'>
        <div className="programmes-title" id="programmes-title">
          <div className='container'>
            <div className="d-flex justify-content-center position-relative">
                <img src={star} alt="star" className="programmes-star position-absolute"/>
                <span className="fs-1 secondary-color fw-bold ">{programme.Title}</span>
            </div>
          </div>
        </div>
        <div className='programmes-content'>
          <div className='container'>
            <div className='row m-0 g-0'>
              <div className='col-12 col-lg-6'>
                <div className='content-txt d-flex justify-content-center py-5'>
                    <span className='w-100 fs-5 text-center' dangerouslySetInnerHTML={{ __html: programme.DetailsText }}></span>
                </div>
              </div>
              <div className='col-12 col-lg-6'>
                <div className='video d-flex justify-content-center w-100'>
                    <iframe className='border border-0 rounded-3 w-100' height="400" src={programme.DescriptionVideo} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='programmes-txt'>
          <div className='container'>
            <div className="row g-0 m-0">
              <div className="col-12 col-lg-4">
                  <div className="programmes-bg-1 d-flex flex-column text-center">
                      <span className='fs-5 mb-2'>حالة الالتحاق</span>
                      <span className='secondary-color fs-3 fw-bold mb-2'>{
                        profile?
                          userProgrammeStatus ==="subscribed"?
                            "انت مشترك فى البرنامج"
                          :
                          userProgrammeStatus ==="pending"?
                            "انتظار القبول فى البرنامج"
                          :
                          (<button className="btn login-btn border-0" type="button" onClick={handleUserProgrammeSubscribed}>
                            <span className='fs-5 fw-bold'>الاشتراك فى البرنامج</span>
                          </button>)
                        :
                          (<button className="btn login-btn border-0" type="button" onClick={handleShow}>
                          <span className='fs-5 fw-bold'>تسجيل الدخول</span>
                        </button>)
                      }</span>
                  </div>
              </div>
              <div className="col-12 col-lg-4">
                  <div className="programmes-bg-2 d-flex flex-column text-center">
                      <span className='fs-5 mb-2'>السعر</span>
                      <span className='secondary-color fs-3 fw-bold mb-2'>{programme.EnrollStatus === 'free'?'مجاني':programme.Price}</span>
                  </div>
              </div>
              <div className="col-12 col-lg-4">
                  <div className="programmes-bg-3 d-flex flex-column text-center">
                      <span className='fs-5 mb-2'>البدء</span>
                      <span className='secondary-color fs-3 fw-bold mb-2'>{programme.StartType === "anytime"?
                      'حياكم':
                      `${programme.StartDate} : ${programme.EndDate}
                      `
                      }</span>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='programmes-pdf pt-5'>
        <div className='container'>
          <div className='prog-pdf-titel mt-2 mb-4'>
            <span className='fs-2 fw-bold'>{`الدليل التعريفي لبرنامج ${programme.Title}`}</span>
          </div>
          <div className='prog-pdf-view'>
            {programme.DescriptionPDF && 
            <iframe src={`http://localhost:8000/${programme.DescriptionPDF}`} width="100%" height="600px" title='pdffff' />
            // <object data={`http://localhost:8000/${programme.DescriptionPDF}`} type="application/pdf" style={{width:'100%',height:'800px'}} aria-label={`تضمين الدليل-التعريفي-لبرنامج-${programme.Title}.`}>
            // </object>
            }
          </div>
        </div>
      </div>
      <ContactSection/>
    </section>
  )

}

