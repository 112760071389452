import React, { useState , useEffect} from 'react'
import './Login.scss'

import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { userLogin } from "../../store/actions/auth";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { axiosInstance } from "../../network/axios";


export default function Login({handleClose,setnotifyMessage,setShowNotifyModal,setnotifytoken}) {
  const history = useHistory();
  const dispatch = useDispatch();
  // let auth  = useSelector(state => state.auth);
  // let user = useSelector((state) => state.users.user);

  const [showPassword, setShowPassword] = useState(false);
  const [disabledBtn, setdisabledBtn] = useState(true);

  const toggleShowPassword = () => {
      setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const [form, setForm] = useState({
      inputUser: "",
      inputPassword: "",
  });
  const [formErrors, setFormError] = useState({
      inputUserErr: null,
      inputPasswordErr: null,
  });

  const handleEnglishKeyPress = (event) => {
    if (
        !(
            /^[a-zA-Z0-9!@#$%^&*]$/.test(event.key) || 
            event.key === 'Backspace' ||
            event.key === 'ArrowLeft' ||
            event.key === 'ArrowRight' ||
            event.key === 'Tab'
        )
    ) {
        event.preventDefault();
    }
};

  const handelFormChange = (e) => {
    if (e.target.name === "inputUser") {
      setdisabledBtn(false)
        setForm({
            ...form,
            inputUser: e.target.value,
        });
        setFormError({
            ...formErrors,
            inputUserErr:
                e.target.value.length === 0
                    ? "* هذا الحقل مطلوب"
                    : null,
        });
    } else if (e.target.name === "inputPassword") {
      setdisabledBtn(false)
      setForm({
          ...form,
          inputPassword: e.target.value,
      });
      setFormError({
          ...formErrors,
          inputPasswordErr:
              e.target.value.length === 0
                  ? "* هذا الحقل مطلوب"
                  : null,
      });
  }
};

const validationForm = ()=>{
  setFormError({
  ...formErrors,
  inputUserErr: form.inputUser === "" ?"* هذا الحقل مطلوب" :null,
  inputPasswordErr:  form.inputPassword === ""? "* هذا الحقل مطلوب":null,
  });
}

  useEffect(() => {}, [form]);

const handleFormSubmit = async (e) => {
  e.preventDefault();
  setdisabledBtn(true)
  if(form.inputUser === "" || form.inputPassword === ""){
    validationForm()
    return
    }
    try{
      let res = await userLogin({
          Username : form.inputUser,
          Password:form.inputPassword
      })
      if(res.data){
        setForm({
          inputUser: "",
          inputPassword: "",
        })
        setFormError({
            inputUserErr: null,
            inputPasswordErr: null,
        })
        let ress = await axiosInstance.get(`/user/user`, {
            headers: {
            authorization: res.data.token,
            },
        })
        dispatch({
          type: "GET_USER",
          payload: ress.data,
        });
        if(!ress.data.Active && ress.data.SoftDelete){
          handleClose()
          setShowNotifyModal(true)
          setnotifyMessage("Active")
        }else{
          if(!ress.data.ActivatedEmail){
            handleClose()
            setShowNotifyModal(true)
            setnotifyMessage("ActivatedEmail")
            setnotifytoken(res.data)
          }else{ 
            if(!ress.data.Approval){
              handleClose()
              setShowNotifyModal(true)
              setnotifyMessage("Approval")
            }else{
              toast.success("تم تسجيل الدخول بنجاح");
              window.localStorage.setItem("auth", JSON.stringify(res.data))
              dispatch({
                  type: "LOGGED_IN_USER",
                  payload: res.data,
              });
              handleClose()
            }
          }
        }
      }
    } catch (err) {
      console.log(err)
        if(err.response.data === "UN_AUTH_USER" || err.response.data === "UN_AUTH_PASSWORD"){
            toast.error("اسم المستخدم أو كلمة المرور غير صحيحة");
        }
    }
  };

  return (
    <section className='login w-50' id='login'>
      <form onSubmit={(e) => handleFormSubmit(e)}>
        <div className="mt-3 position-relative">
            <label htmlFor="inputUser" className="form-label secondary-color fs-6">اسم المستخدم</label>
            <span className='position-absolute user-icon'>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person" viewBox="0 0 16 16">
                <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
              </svg>
            </span>
            <input type="text" className="form-control pe-5" id="inputUser" name="inputUser" aria-describedby="inputuser" value={form.inputUser} onChange={(e) => handelFormChange(e)} />
            <div className="pt-1 text-danger" id='inputuser'>
                <small>
                    {formErrors.inputUserErr}
                </small>
            </div>
        </div>
        <div className="mt-3 mb-3 position-relative">
            <label htmlFor="inputPassword" className="form-label secondary-color fs-6">كلمة المرور</label>
            <span className='position-absolute pass-icon'>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-lock" viewBox="0 0 16 16">
                <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2m3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2M5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1"/>
              </svg>
            </span>
            <input type={showPassword ? 'text' : 'password'} className="form-control pe-5" id="inputPassword" name="inputPassword" aria-describedby="inputpassword" value={form.inputPassword} onChange={(e) => handelFormChange(e)} onKeyDown={(e) =>handleEnglishKeyPress(e)}/>
            <button type='button' className='position-absolute pass-btn border-0' onClick={toggleShowPassword}>
              {showPassword ? 
              <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                      <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"/>
                      <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/>
                  </svg>
              </span>
              :
              <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-slash" viewBox="0 0 16 16">
                      <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z"/>
                      <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829"/>
                      <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z"/>
                  </svg>
              </span>
              }
            </button>
            <div className="pt-1 text-danger" id='inputpassword'>
                <small>
                    {formErrors.inputPasswordErr}
                </small>
            </div>
        </div>

        <div className="d-flex justify-content-center">
          <button type="submit" className="btn rounded-pill text-white px-5 mx-3"
          disabled={formErrors.inputUserErr || formErrors.inputPasswordErr || disabledBtn}>دخول</button>
        </div>
      </form>
  </section>
  )
}
