// import React, { useEffect} from 'react'
import "./Home.scss";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom'
import { useParams } from "react-router-dom";
import axios from "axios";

import ContactSection from "../../Components/ContactSection/ContactSection";
import { axiosInstance } from "../../network/axios";
import Hero from '../../assets/images/hero.jpg'
import drabd from '../../assets/images/jXmSpAbu_400x400.jpg'
import star from '../../assets/images/star.webp'
import programme1 from '../../assets/images/programme - 768x1024.jpg'
import border from '../../assets/images/border.png'
import prog1 from '../../assets/images/prog-1.png'
import prog2 from '../../assets/images/prog-2.png'
import prog3 from '../../assets/images/prog-3.png'
import prog4 from '../../assets/images/prog-4.png'
import PassModal from "../../Components/PassModal/PassModal";
import MessageModal from "../../Components/MessageModal/MessageModal";
import ActivatedModal from "../../Components/ActivatedModal/ActivatedModal";

// import ContactForm from '../../Components/ContactForm/ContactForm'

export default function Home() {
    const location = useLocation();
    const params = useParams();
    const [showChangePassModal,setShowChangePassModal] = useState(false)
    const [showActivatedModal,setShowActivatedModal] = useState(false)
    const [showCheckModal,setShowCheckModal] = useState({
        open:false,
        message:""
    })
    const[prayerDateH,setPrayerDateH]=useState()
    const[prayerDateM,setPrayerDateM]=useState()
    const[prayertimes,setPrayertimes]=useState({
        Fajr: "",
        Sunrise: "",
        Dhuhr: "",
        Asr: "",
        Sunset: "",
        Maghrib: "",
        Isha: "",
        Imsak: "",
        Midnight: "",
        Firstthird: "",
        Lastthird: ""
    })
    
    // window.addEventListener('scroll',()=>{
    //     const logo = document.querySelector("div.logo-img img")
    //     logo.style.width = `${100-window.scrollY/2}%`
    // });

    const getGeoInfo = async() => {
        await axios.get("https://ipapi.co/json/")
          .then(async(response) => {
            // console.log(" IP ==> ",response.data)
            let data = response.data;
            await axios.get('https://api.aladhan.com/v1/timingsByCity',{
                params:{
                    country: data.country,
                    city: data.city
                }
            }).then((res)=>{
                // console.log(" Athan ==>",res.data)
                setPrayertimes(res.data.data.timings)
                setPrayerDateH(res.data.data.date.hijri)
                setPrayerDateM(res.data.data.date.readable)
            }).catch((err)=>{
                console.log(err)
            })
          })
          .catch((error) => {
            console.log(error);
          });
      };

      const handleChangePassModal = async()=>{
        if(location.pathname === `/changepassword/${params.id}/${params.uniqueString}`){
            try{
                let res = await axiosInstance.post("/user/checkforgetpassword", {
                    ID : params.id,
                    UniqueString : params.uniqueString
                })
                if(res.data.status !== 'FAILED'){
                    setShowChangePassModal(true)
                }else{
                    setShowCheckModal({
                        open:true,
                        message:res.data.message
                    })
                }
            }catch(err){
            console.log(err)
            }
        }else if(location.pathname === `/activatedemail/${params.id}/${params.uniqueString}`){
            try{
                let res = await axiosInstance.post("/user/checkactivatedemail", {
                    ID : params.id,
                    UniqueString : params.uniqueString
                })
                if(res.data.status !== 'FAILED'){
                    setShowActivatedModal(true)
                }else{
                    setShowCheckModal({
                        open:true,
                        message:res.data.message
                    })
                }
            }catch(err){
            console.log(err)
            }
        }
      }

    useEffect(()=>{
        getGeoInfo()
        if(Object.keys(params).length !== 0){
            handleChangePassModal()
        }
    },[])

    const handleLinkClick=()=>{
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
    }

    return (
        <section className="home" id="home">
            {showChangePassModal && <PassModal showChangePassModal={showChangePassModal} setShowChangePassModal = {setShowChangePassModal} params={params}/>}
            {showCheckModal.open && <MessageModal showCheckModal={showCheckModal} setShowCheckModal={setShowCheckModal} />}
            {showActivatedModal && <ActivatedModal showActivatedModal={showActivatedModal} setShowActivatedModal={setShowActivatedModal} params={params}/>}
            <header className="home-header" id="home-header">
                <div className="">
                    <div id="carouselHeaderCaptions" className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-indicators">
                            <button type="button" data-bs-target="#carouselHeaderCaptions" data-bs-slide-to="2" className="active" aria-current="true" aria-label="Slide 3"></button>
                            <button type="button" data-bs-target="#carouselHeaderCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselHeaderCaptions" data-bs-slide-to="0" aria-label="Slide 1"></button>
                        </div>
                        <div className="carousel-inner">
                            <div className="carousel-item active">
                            <img src={Hero} className="d-block" alt="..."/>
                            </div>
                            <div className="carousel-item">
                            <img src={Hero} className="d-block" alt="..."/>
                            </div>
                            <div className="carousel-item">
                            <img src={Hero} className="d-block" alt="..."/>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselHeaderCaptions" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselHeaderCaptions" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </header>

            <section className='home-about' id='home-about'>
                <div className='container'>
                    <div className="about-title" id="about-title">
                        <div className="d-flex justify-content-center position-relative">
                            <img src={star} alt="star" className="about-star position-absolute"/>
                            <span className="fs-1 secondary-color fw-bold ">عن الاكاديمية</span>
                        </div>
                    </div>
                    <div className='row g-0'>
                        <div className='col-12 col-lg-6 mb-5'>
                            <div className='d-flex justify-content-center'>
                                <div className='d-flex flex-column pt-5 mt-5'>
                                    <span className='fs-1 mb-3 fw-bold secondary-color'>أكاديمية آيات للعلوم الشرعية</span>
                                    <span className='fs-4 mb-3 about-txt'>أكاديمية تابعة لجمعية آيات الخيرية 
                                    <br/>متخصصة في الدورات الشرعية ودورات علوم القرآن الكريم</span>
                                    <span className='fs-4 fw-bold about-txt'>بإشراف فضيلة الدكتور عبد المحسن بن زبن المطيري</span>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-lg-6'>
                            <div className='img-div d-flex justify-content-end position-relative'>
                                <img src={drabd} alt='Dr/Abd Elmohsan' className='w-75'/>
                                <div className='position-absolute w-75 text-center fs-3 px-1'>
                                    <span>فضيلة الدكتور</span>
                                    <br/>
                                    <span>عبد المحسن بن زبن المطيري</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='home-programme' id="home-programme">
                <div className="programme-title" id="programme-title">
                    <div className="container">
                        <div className="d-flex justify-content-center position-relative">
                            <img src={star} alt="star" className="programme-star position-absolute"/>
                            <span className="fs-1 secondary-color fw-bold ">برنامج بداية المفسر</span>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className='programme-img'>
                        <div className='container'>
                        <div className="row g-0">
                            <div className="col-12 col-lg-4 mb-5">
                                <div className="d-flex flex-column align-items-center">
                                    <div className="position-relative d-flex justify-content-center">
                                        <img src={programme1} className="d-block w-50 border boreder-0 rounded-circle" alt="programme1"/>
                                        <img src={border} alt="programme" className="w-75 programme-border position-absolute"/>
                                    </div>
                                    <div className='d-flex justify-content-center my-5 pt-2'>
                                        <button className="btn button-bg-color border-0 rounded-pill" type="button" data-bs-toggle="modal" href="#exampleModalToggle">التسجيل</button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 mb-5">
                                <div className='d-flex flex-column pt-5 px-3 programme-txt'>
                                    <span className='fs-4'>فكرة البرنامج</span>
                                    <span className=''>برنامج علمي متخصص لإعداد المفسر، يعني بتخريج طلاب علم مطلعين بمجالات علم التفسير، اعتمادا على منصات التواصل الحديثة.</span>
                                    <span className='fs-4'>مدة البرنامج</span>
                                    <span className=''>تسعة أشهر (شاملة فترة الإجازات والتوقفات والاختبارات).</span>
                                    <div className="d-flex justify-content-end mt-4">
                                        <Link className="text-decoration-none text-start fw-bold button-bg-color py-2 px-3 rounded-pill" to={`/programmes/${1}`} smooth="true" onClick={handleLinkClick}>المزيد ...</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4">
                                <div className='video d-flex justify-content-center w-100'>
                                    <iframe className='border border-0 rounded-3 w-100' height="315" src="https://www.youtube.com/embed/q2b4sPZf7zI?si=J0hATA6TOhoOO6zX" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='home-programmes' id="home-programmes">
                <div className="container">
                    <div className="programmes-title" id="programmes-title">
                        <div className="d-flex justify-content-center position-relative">
                            <img src={star} alt="star" className="programmes-star position-absolute"/>
                            <span className="fs-1 secondary-color fw-bold ">برامج الأكاديمية</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-3">
                            <div className="d-flex flex-column align-items-center">
                                <img src={prog1} alt="star" className="programmes-star w-50"/>
                                <span className="mt-3 fs-4 fw-bold secondary-color text-center prog-title">برنامج بداية المفسر _ الدفعة السابعة</span>
                                <span className="prog-details mb-3 fs-6 text-center">بإشراف فضيلة الشيخ الدكتور عبد المحسن زبن المطيري</span>
                                <div className='d-flex justify-content-center mb-5'>
                                    <Link className="border border-0 rounded-pill py-2 px-3 text-decoration-none button-bg-color" to={`/programmes/${1}`} smooth="true" onClick={handleLinkClick}>المزيد</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3">
                            <div className="d-flex flex-column align-items-center">
                                <img src={prog2} alt="star" className="programmes-star w-50"/>
                                <span className="mt-3 fs-4 fw-bold secondary-color text-center prog-title">تفسير الزهراوين</span>
                                <span className="prog-details mb-3 fs-6 text-center">تعليق أ د عبد المحسن زبن المطيري علي المختصر في التفسير لسورتي البقرة وآل عمران</span>
                                <div className='d-flex justify-content-center mb-5'>
                                    <Link className="border border-0 rounded-pill py-2 px-3 text-decoration-none button-bg-color"  to={`/programmes/${1}`} smooth="true" onClick={handleLinkClick}>المزيد</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3">
                            <div className="d-flex flex-column align-items-center">
                                <img src={prog3} alt="star" className="programmes-star w-50"/>
                                <span className="mt-3 fs-4 fw-bold secondary-color text-center prog-title">فتح الخبير بما لابد حفظه في علم التفسير</span>
                                <span className="prog-details mb-3 fs-6 text-center">(جمع تفسير ابن عباس -رضي الله عنه-) ?
                                للعلامة: الشّاه ولي الله الدّهلوي</span>
                                <div className='d-flex justify-content-center mb-5'>
                                    <Link className="border border-0 rounded-pill py-2 px-3 text-decoration-none button-bg-color"  to={`/programmes/${1}`} smooth="true" onClick={handleLinkClick}>المزيد</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3">
                            <div className="d-flex flex-column align-items-center">
                                <img src={prog4} alt="star" className="programmes-star w-50"/>
                                <span className="mt-3 fs-4 fw-bold secondary-color text-center prog-title">المختصر في التفسير</span>
                                <span className="prog-details mb-3 fs-6 text-center">بإشراف وتعليق د عبد المحسن زبن المطيري</span>
                                <div className='d-flex justify-content-center mb-5'>
                                    <Link className="border border-0 rounded-pill py-2 px-3 text-decoration-none button-bg-color"  to={`/programmes/${1}`} smooth="true" onClick={handleLinkClick}>المزيد</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='home-courses' id="home-courses">
                <div className="courses-title" id="courses-title">
                    <div className="container">
                        <div className="d-flex justify-content-center position-relative">
                            <img src={star} alt="star" className="courses-star position-absolute"/>
                            <span className="fs-1 secondary-color fw-bold ">دورات الأكاديمية</span>
                        </div>
                    </div>
                </div>
                <div className='container'>
                    <div className='courses-img'>
                        <div className="row g-0">
                            <div className="col-12 col-lg-4 mb-5">
                                <div className="d-flex flex-column align-items-center">
                                    <div className="position-relative d-flex justify-content-center mb-5">
                                        <img src={programme1} alt="programme" className="w-50 border border-0 rounded-circle"/>
                                        <img src={border} alt="programme" className="w-75 courses-border position-absolute"/>
                                    </div>
                                    <div className='d-flex justify-content-center mb-5 pt-5'>
                                        <Link className="border border-0 rounded-pill py-2 px-3 text-decoration-none button-bg-color"  to={`/courses/${1}`} smooth="true" onClick={handleLinkClick}>المزيد</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 mb-5">
                                <div className="d-flex flex-column align-items-center">
                                    <div className="position-relative d-flex justify-content-center mb-5">
                                        <img src={programme1} alt="programme" className="w-50 border border-0 rounded-circle"/>
                                        <img src={border} alt="programme" className="w-75 courses-border position-absolute"/>
                                    </div>
                                    <div className='d-flex justify-content-center mb-5 pt-5'>
                                        <Link className="border border-0 rounded-pill py-2 px-3 text-decoration-none button-bg-color"  to={`/courses/${1}`} smooth="true" onClick={handleLinkClick}>المزيد</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4">
                                <div className="d-flex flex-column align-items-center">
                                    <div className="position-relative d-flex justify-content-center mb-5">
                                        <img src={programme1} alt="programme" className="w-50 border border-0 rounded-circle"/>
                                        <img src={border} alt="programme" className="w-75 courses-border position-absolute"/>
                                    </div>
                                    <div className='d-flex justify-content-center mb-5 pt-5'>
                                        <Link className="border border-0 rounded-pill py-2 px-3 text-decoration-none button-bg-color"  to={`/courses/${1}`} smooth="true" onClick={handleLinkClick}>المزيد</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='home-pray' id='home-pray'>
                <div className="pray-title" id="pray-title">
                    <div className="container">
                        <div className="d-flex justify-content-center position-relative">
                            <img src={star} alt="star" className="pray-star position-absolute"/>
                            <span className="fs-1 secondary-color fw-bold ">مواقيت الصلاة</span>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row g-0 m-0">
                        <div className="col-12 col-lg-6">
                            <div className="pray-img w-100"></div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="pray-txt d-flex flex-column align-items-center">
                                <div className="d-flex flex-column align-items-center w-50 mb-3">
                                    <span className="fs-2">التاريخ</span>
                                    <span className="fs-3">{prayerDateH?.date}</span>
                                </div>
                                <div className="d-flex justify-content-between w-50 mb-3">
                                    <span className="fs-3">الفجر</span>
                                    <span className="fs-3">{prayertimes.Fajr}</span>
                                </div>
                                <div className="d-flex justify-content-between w-50 mb-3">
                                    <span className="fs-3">الظهر</span>
                                    <span className="fs-3">{prayertimes.Dhuhr}</span>
                                </div>
                                <div className="d-flex justify-content-between w-50 mb-3">
                                    <span className="fs-3">العصر</span>
                                    <span className="fs-3">{prayertimes.Asr}</span>
                                </div>
                                <div className="d-flex justify-content-between w-50 mb-3">
                                    <span className="fs-3">المغرب</span>
                                    <span className="fs-3">{prayertimes.Maghrib}</span>
                                </div>
                                <div className="d-flex justify-content-between w-50 mb-3">
                                    <span className="fs-3">العشاء</span>
                                    <span className="fs-3">{prayertimes.Isha}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <ContactSection/>

        </section>
    )
}
