import React, { useState } from 'react'
import "./LoginModal.scss"
import Login from '../Login/Login'
import Register from '../Register/Register'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { axiosInstance } from "../../network/axios";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default function LoginModal({show, handleShow, handleClose}) {
    const emailPattern= new RegExp("^[^\\s@]+@([^\\s@.,]+\\.)+[^\\s@.,]{2,}$")
    const [showRegModal,setShowRegModal] = useState(false)
    const [showForgetModal,setShowForgetModal] = useState(false)
    const [showNotifyModal,setShowNotifyModal] = useState(false)
    const [notifyMessage,setnotifyMessage] = useState("")
    const [notifytoken,setnotifytoken] = useState()
    const [disabledBtn, setdisabledBtn] = useState(true);
    const [form, setForm] = useState({
        inputEmail: "",
    });
    const [formErrors, setFormError] = useState({
        inputEmailErr: null,
    });

    const handleshowRegModal =()=>{
        handleClose()
        setShowRegModal(true)
    }
    const hideRegModal =()=>{
        setShowRegModal(false)
    }

    const handleshowLoginModal =()=>{
        setShowRegModal(false)
        handleShow()
    }

    const handleShowForgetModal =()=>{
        handleClose()
        setShowForgetModal(true)
    }
    const hideForgetModal =()=>{
        setForm({inputEmail:""})
        setShowForgetModal(false)
    }
    const hideNotifyModal =()=>{
        setShowNotifyModal(false)
    }

    const handleSendActivatedEmail =async(notifytoken)=>{
        console.log("handleSendActivatedEmail" ,notifytoken)
        try{
            await axiosInstance.get(`/user/reactivatedemail`, {
                headers: {
                    authorization: notifytoken.token,
                },
            })
            toast.success("تم ارسال الايميل بنجاح");
        }catch(err){
            console.log(err)
        }
        setShowNotifyModal(false)
    }

    
    const handelFormChange = (e) => {
      if (e.target.name === "inputEmail") {
        setdisabledBtn(false)
          setForm({
              ...form,
              inputEmail: e.target.value,
          });
          setFormError({
            ...formErrors,
            inputEmailErr:
                e.target.value.length === 0
                    ? "* هذا الحقل مطلوب"
                    : !emailPattern.test(e.target.value)
                    ? "* الايميل غير صحيح (exapmle@ayaat.com)"
                    : null,
        });
      }
    };
  
    const validationForm = ()=>{
        setFormError({
        ...formErrors,
        inputEmailErr: form.inputEmail === "" ?"* هذا الحقل مطلوب" :null,
        });
    }

    const handleFormSubmit = async (e) => {
        setdisabledBtn(true)
        e.preventDefault();
        if(form.inputEmail === ""){
          validationForm()
          return
          }
          try{
            let res = await axiosInstance.post("/user/forgetpassword", {
                Email : form.inputEmail
            })
            if(res.data){
                if(res.data.status !== 'FAILED'){
                    setForm({
                        inputEmail: "",
                    })
                    setFormError({
                        inputEmailErr: null,
                    })
                    toast.success(res.data.message);
                    hideForgetModal()
                }else{
                    setFormError({
                        inputEmailErr: `* ${res.data.message}`
                    })
                    toast.error(res.data.message);
                }
            }
          }catch(err){
            console.log(err)
          }
        }
    
  return (
    <section className='login-modal' id='login-modal'>
        <Modal show={show} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className='modalContent'>
                <Modal.Header closeButton></Modal.Header>
                <div className="modal-body d-flex flex-column align-items-center">
                    <span className='fs-1 fw-bold secondary-color m-auto'>تسجيل الدخول</span>
                    <Login handleClose={handleClose} setnotifyMessage={setnotifyMessage} setShowNotifyModal={setShowNotifyModal} setnotifytoken={setnotifytoken}/>
                    <button className="btn border-0 secondary-color text-decoration-underline bg-transparent mt-1" onClick={handleShowForgetModal}>هل نسيت كلمة المرور؟</button>
                    <span className='fs-4 fw-bold secondary-color mt-5 my-3'>هل هذه هي المرة الأولى لك؟</span>
                    <Button className="modalBtn btn rounded-pill text-white px-5 mx-3 btn-bg" onClick={handleshowRegModal}>إنشاء حساب جديد</Button>
                </div>
            </Modal.Body>
        </Modal>

        <Modal show={showRegModal} onHide={hideRegModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className='modalContent'>
                <Modal.Header closeButton></Modal.Header>
                <div className="modal-body d-flex flex-column align-items-center">
                    <span className='title fs-1 fw-bold secondary-color'>إنشاء حساب جديد</span>
                    <Register handleshowLoginModal={handleshowLoginModal}/>
                    <span className='fs-4 fw-bold secondary-color mt-5 my-3'>هل تمتلك حساب؟</span>
                    <button className="modalBtn btn rounded-pill text-white px-5 mx-3 btn-bg" onClick={handleshowLoginModal}>تسجيل الدخول</button>
                </div>
            </Modal.Body>
        </Modal>

        <Modal show={showForgetModal} onHide={hideForgetModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className='modalContent'>
                <Modal.Header closeButton></Modal.Header>
                <div className="modal-body d-flex flex-column align-items-center">
                    <span className='title fs-1 fw-bold secondary-color'>البحث بالبريد الالكتروني</span>
                    <span className='title fs-4 fw-bold secondary-color'>قم بأدخال البريد الالكتروني وسوف يتم ارسال رسالة</span>
                </div>
                <div className='w-50 mx-auto'>
                    <form onSubmit={(e) => handleFormSubmit(e)}>
                        <div className="mt-3 position-relative">
                            <label htmlFor="inputEmail" className="form-label secondary-color fs-6">البريد الالكتروني</label>
                            <span className='position-absolute user-icon'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
                                </svg>
                            </span>
                            <input type="text" className="form-control pe-5" id="inputEmail" name="inputEmail" aria-describedby="inputemail" value={form.inputEmail} onChange={(e) => handelFormChange(e)} />
                            <div className="pt-1 text-danger" id='inputemail'>
                                <small>
                                    {formErrors.inputEmailErr}
                                </small>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center">
                        <button type="submit" className="modalBtn btn rounded-pill text-white px-5 my-3"
                        disabled={formErrors.inputEmailErr || disabledBtn}>ارسال الايميل</button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>

        <Modal show={showNotifyModal} onHide={hideNotifyModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className='modalContent'>
                <Modal.Header closeButton></Modal.Header>
                <div className="modal-body d-flex flex-column align-items-center">
                    <span className='title fs-1 fw-bold secondary-color'>{
                        notifyMessage === "Active" ? "الحساب موقوف يرجى التواصل مع الاكاديمية" :
                        notifyMessage === "ActivatedEmail" ? "يرجي تفعيل الحساب من خلال الايميل" :
                        notifyMessage === "Approval" ? "لم يتم تفعيل الحساب  بعد يرجي الانتظار أو التواصل مع الاكاديمية" : ""
                    }</span>
                    {
                        notifyMessage === "ActivatedEmail"?(<>
                            <div className='my-3'>
                                <span className='fs-4 fw-bold secondary-color'>لارسال كود التفعيل مرة اخرى</span>
                            </div>
                            <button className="modalBtn btn rounded-pill text-white px-5 mx-3 btn-bg" onClick={()=>{handleSendActivatedEmail(notifytoken)}} name='activatedEmail'>إضغط هنا</button>
                        </>):""
                    }
                </div>
            </Modal.Body>
        </Modal>

    </section>
  )
}

