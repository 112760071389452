import React from 'react'
import "./About.scss";

import HeaderSection from '../../Components/HeaderSection/HeaderSection'
import image from '../../assets/images/hero.jpg'
import star from '../../assets/images/star.webp'
import drabd from '../../assets/images/jXmSpAbu_400x400.jpg'
import ContactSection from '../../Components/ContactSection/ContactSection'

export default function About() {
  return (
    <section className='about'   id='about'>
        <HeaderSection image={image} flower={null}/>

        <div className='about-bg'>
            <div className='container'>
                <div className="about-title" id="about-title">
                    <div className="d-flex justify-content-center position-relative">
                        <img src={star} alt="star" className="about-star position-absolute"/>
                        <span className="fs-1 secondary-color fw-bold ">عن الاكاديمية</span>
                    </div>
                </div>
                <div className='row g-0'>
                    <div className='col-12 col-lg-6 mb-5'>
                        <div className='d-flex justify-content-center'>
                            <div className='d-flex flex-column pt-5 mt-5'>
                                <span className='fs-1 mb-3 fw-bold secondary-color'>أكاديمية آيات للعلوم الشرعية</span>
                                <span className='fs-4 mb-3'>أكاديمية تابعة لجمعية آيات الخيرية 
                                <br/>متخصصة في الدورات الشرعية ودورات علوم القرآن الكريم</span>
                                <span className='fs-4 fw-bold'>بإشراف فضيلة الدكتور عبد المحسن بن زبن المطيري</span>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-lg-6'>
                        <div className='img-div d-flex justify-content-end position-relative'>
                            <img src={drabd} alt='Dr/Abd Elmohsan' className='w-75'/>
                            <div className='position-absolute w-75 text-center fs-3 px-1'>
                                <span>فضيلة الدكتور</span>
                                <br/>
                                <span>عبد المحسن بن زبن المطيري</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ContactSection/>
    </section>
    )
}
