import './App.scss';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Navbar from './Components/Navbar/Navbar';
import LoginModal from './Components/LoginModal/LoginModal';
import Home from './pages/Home/Home';
import Programmes from './pages/Programmes/Programmes';
import Courses from './pages/Courses/Courses';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import Footer from './Components/Footer/Footer';
import Profile from './pages/Profile/Profile';
import NotFound from './pages/NotFound/NotFound';
import { ToastContainer } from "react-toastify";
import { useState } from 'react';

export default function App() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true); 

  return (
    <BrowserRouter>
      <ToastContainer />
      <Navbar handleShow={handleShow}/>
      <LoginModal show={show} handleShow={handleShow} handleClose={handleClose}/>
      <Switch>
        <Route path={'/'} exact component={Home} />
        <Route path={'/changepassword/:id/:uniqueString'} exact component={Home} />
        <Route path={'/activatedemail/:id/:uniqueString'} exact component={Home} />
        <Route path={'/programmes/:id'} exact component={()=><Programmes handleShow={handleShow}/>} />
        <Route path={'/courses/:id'} exact component={Courses} />
        <Route path={'/profile'} exact component={Profile} />
        <Route path={'/about'} exact component={About} />
        <Route path={'/contact'} exact component={Contact} />
        <Route path={'*'} exact component={NotFound} />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}
