// import React, { useState , useContext , useEffect} from 'react'
import "./NotFound.scss";

export default function NotFound() {
    
    return (
        <section className='notFound m-0' id='notFound'>
            Not Found
        </section>
    )
}
