import React, { useState , useContext , useEffect} from 'react'
import "./ContactForm.scss"
import { axiosInstance } from "../../network/axios";
import PhoneInput from 'react-phone-number-input'
import { isValidPhoneNumber } from 'react-phone-number-input'
import ar from 'react-phone-number-input/locale/ar'

export default function ContactForm() {
    const emailPattern = new RegExp("^[^\\s@]+@([^\\s@.,]+\\.)+[^\\s@.,]{2,}$");
    const [disabledBtn, setdisabledBtn] = useState(true);
    const [form, setForm] = useState({
        inputName: "",
        inputPhone: "",
        inputEmail: "",
        inputDetails:""
    });
    const [phone, setPhone] = useState("")
    const [formErrors, setFormError] = useState({
        inputNameErr: null,
        inputPhoneErr: null,
        inputEmailErr: null,
        inputDetailsErr: null
    });
    const handelFormChange = (e) => {
        if (e.target.name === "inputEmail") {
            setdisabledBtn(false)
            setForm({
                ...form,
                inputEmail: e.target.value,
            });
            setFormError({
                ...formErrors,
                inputEmailErr:
                    e.target.value.length === 0
                        ? "* هذا الحقل مطلوب"
                        : !emailPattern.test(e.target.value)
                            ? "* يجب ان يكون الايميل بهذا الشكل example@gmail.com"
                            : null,
            });
        } else if (e.target.name === "inputName") {
            setdisabledBtn(false)
            setForm({
                ...form,
                inputName: e.target.value,
            });
            setFormError({
                ...formErrors,
                inputNameErr:
                    e.target.value.length === 0
                        ? "* هذا الحقل مطلوب"
                        : e.target.value.length < 3
                            ? "* الاسم يجب ان يتكون من 3 أحرف فأكثر"
                            : e.target.value.length > 25
                                ? "* الاسم يجب الا يزيد عن 15 حرف"
                                : null,
            });
        } else if (e.target.name === "inputPhone") {
            setdisabledBtn(false)
            setForm({
                ...form,
                inputPhone: e.target.value,
            });
            setFormError({
                ...formErrors,
                inputPhoneErr:
                    e.target.value.length === 0
                        ? "* هذا الحقل مطلوب"
                        : null,
            });
        } else if (e.target.name === "inputDetails") {
            setdisabledBtn(false)
            setForm({
                ...form,
                inputDetails: e.target.value,
            });
            setFormError({
                ...formErrors,
                inputDetailsErr:
                    e.target.value.length === 0
                        ? "* هذا الحقل مطلوب"
                        : null,
            });
        }
    };

    const handleFormReset = () => {
        setForm({
            inputName: "",
            inputPhone: "",
            inputEmail: "",
            inputDetails:""
        })
        setFormError({
            inputNameErr: null,
            inputPhoneErr: null,
            inputEmailErr: null,
            inputDetailsErr: null
        })
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setdisabledBtn(true)
            // console.log(isValidPhoneNumber(phone))
        
        if(formErrors?.inputNameErr == null && formErrors?.inputPhoneErr == null && formErrors?.inputEmailErr == null && formErrors?.inputDetailsErr == null && isValidPhoneNumber(phone)){
            try{
                await axiosInstance.post("/customers",{
                    name:form.inputName,
                    phone:phone,
                    email:form.inputEmail,
                    details:form.inputDetails
                    });
                    setForm({
                        inputName: "",
                        inputPhone: "",
                        inputEmail: "",
                        inputDetails:""
                    })
                    setPhone("")
                    setFormError({
                        inputNameErr: null,
                        inputPhoneErr: null,
                        inputEmailErr: null,
                        inputDetailsErr: null
                    })
                    window.location.reload(false);
            }catch(err){
                // console.log(err)
            }
        }
    };


    return (
        <section className='contact-form'>
            <form className="bgcolor p-4 border border-0 rounded-3 w-100" onSubmit={(e) => handleFormSubmit(e)}>
                <div className="">
                    <label htmlFor="name" className="form-label secondary-color">الاسم</label>
                    <input type="text" className="form-control" id="inputName" name="inputName" aria-describedby="inputname" value={form.inputName} onChange={(e) => handelFormChange(e)} />
                    <div className="pt-1 text-danger" id='inputname'>
                        <small>
                            {formErrors.inputNameErr}
                        </small>
                    </div>
                </div>
                <div>
                    <label htmlFor="phone" className="form-label secondary-color">رقم الهاتف</label>
                    <PhoneInput
                        labels={ar}
                        name="inputPhone"
                        id="inputPhone"
                        value={phone}
                        onChange={setPhone}
                        className='text-center'
                        />
                    <div className="pt-1 text-danger" id='inputphone'>
                        <small>
                            {phone && !isValidPhoneNumber(phone)?"* هذا الرقم غير مطابق لكود البلد":null}
                        </small>
                    </div>
                </div>

                <div className="">
                    <label htmlFor="email" className="form-label secondary-color">البريد الالكتروني</label>
                    <input type="email" className="form-control" id="inputEmail" name="inputEmail" aria-describedby="inputemail" value={form.inputEmail} onChange={(e) => handelFormChange(e)} />
                    <div className="pt-1 text-danger" id='inputemail'>
                        <small>
                            {formErrors.inputEmailErr}
                        </small>
                    </div>
                </div>

                <div className="pb-3">
                    <label htmlFor="inputDetails" className="form-label secondary-color">التفاصيل</label>
                    <textarea className="form-control" id="inputDetails" rows="3" name="inputDetails" aria-describedby="inputdetails" onChange={(e) => handelFormChange(e)} value={form.inputDetails}></textarea>
                    <div className="pt-1 text-danger" id='inputdetails'>
                        <small>
                            {formErrors.inputDetailsErr}
                        </small>
                    </div>
                </div>
                <div className='d-flex justify-content-end'>
                    <button type="submit" className="btn rounded-3 button-bg-color px-4 ms-3" disabled={disabledBtn || formErrors.inputNameErr || formErrors.inputPhoneErr || formErrors.inputEmailErr || formErrors.inputDetailsErr}>ارسال</button>
                    <button type="button" className="btn rounded-3 button-bg-color px-4" onClick={handleFormReset}>تفريغ الحقول</button>
                </div>
            </form>
        </section>
    )
}
