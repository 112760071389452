import React, { useState } from 'react'
import './PassModal.scss';
import Modal from 'react-bootstrap/Modal';

import { axiosInstance } from "../../network/axios";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default function PassModal({showChangePassModal, setShowChangePassModal, params}) {
    const [disabledBtn, setdisabledBtn] = useState(true);
    const [form, setForm] = useState({
        inputPassword: "",
        inputPasswordConfirm: ""
    });
    const [formErrors, setFormError] = useState({
        inputPasswordErr: null,
        inputPasswordConfirmErr: null
    });

    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const toggleNewShowPassword = () => {
        setShowNewPassword((prevShowPassword) => !prevShowPassword);
        };
    const toggleConfirmShowPassword = () => {
        setShowConfirmPassword((prevShowPassword) => !prevShowPassword);
        };

    const handleEnglishKeyPress = (event) => {
        if (
            !(
                /^[a-zA-Z0-9!@#$%^&*]$/.test(event.key) || 
                event.key === 'Backspace' ||
                event.key === 'ArrowLeft' ||
                event.key === 'ArrowRight' ||
                event.key === 'Tab'
            )
        ) {
            event.preventDefault();
        }
    };

    const hideChangePassModal =()=>{
        setShowChangePassModal(false)
    }

    const handelFormChange = (e) => {
        if (e.target.name === "inputPassword") {
            setdisabledBtn(false)
            setForm({
                ...form,
                inputPassword: e.target.value,
            });
            setFormError({
                ...formErrors,
                inputPasswordErr:
                    e.target.value.length === 0
                    ? "* هذا الحقل مطلوب"
                    : !/.{8,}/.test(e.target.value)
                    ? "* يجب ان لا تقل كلمة المرور عن 8 احرف"
                    : !/[a-z]/.test(e.target.value)
                    ? "* يجب ان تشمل كلمة المرور على الاقل حرف واحد lowercase"
                    : !/[A-Z]/.test(e.target.value)
                    ? "* يجب ان تشمل كلمة المرور على الاقل حرف واحد uppercase"
                    : !/[0-9]/.test(e.target.value)
                    ? "* يجب ان تشمل كلمة المرور على رقم واحد من0 الى 9"
                    : !/[!@#$%^&*]/.test(e.target.value)
                    ? "* يجب ان تشمل كلمة المرور على رمز واحد من هذه الرموز ! @ # $ % ^ & * "
                    : null,
            });
        }
        else if (e.target.name === "inputPasswordConfirm") {
            setdisabledBtn(false)
            setForm({
                ...form,
                inputPasswordConfirm: e.target.value,
            });
            setFormError({
                ...formErrors,
                inputPasswordConfirmErr:
                    e.target.value.length === 0
                        ? "* هذا الحقل مطلوب"
                        : e.target.value !== form.inputPassword
                        ? "* كلمة المرور غير متطابقة"
                        : null,
            });
        }
    };
  
    const validationForm = ()=>{
        setFormError({
        ...formErrors,
        inputPasswordErr:  form.inputPassword === ""? "* هذا الحقل مطلوب":null,
        inputPasswordConfirmErr:  form.inputPasswordConfirm === ""? "* هذا الحقل مطلوب":null,
        });
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setdisabledBtn(true)
        if(form.inputPassword === "" ||  form.inputPasswordConfirm === ""){
          validationForm()
          return
          }
          try{
            let res = await axiosInstance.patch("/user/forgetpassword", {
                ID : params.id,
                UniqueString : params.uniqueString,
                Password : form.inputPassword
            })
            if(res.data.status !== 'FAILED'){
                setForm({
                    inputPassword: "",
                    inputPasswordConfirm: ""
                })
                setFormError({
                    inputPasswordErr: null,
                    inputPasswordConfirmErr: null
                })
                toast.success(res.data.message);
                setShowChangePassModal()
            }else{
                toast.error(res.data.message);
                setFormError({
                    inputPasswordErr: res.data.message
                })
            }
          }catch(err){
            console.log(err)
          }
        }
    
  return (
    <section className='pass-modal'>

        <Modal show={showChangePassModal} onHide={hideChangePassModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className='modalContent'>
                <Modal.Header closeButton></Modal.Header>
                <div className="modal-body d-flex flex-column align-items-center">
                    <span className='title fs-1 fw-bold secondary-color'>تغيير كلمة المرور</span>
                </div>
                <div className='w-50 mx-auto'>
                    <form onSubmit={(e) => handleFormSubmit(e)}>
                        <div className="mt-3 mb-3 position-relative">
                        <label htmlFor="inputPassword" className="form-label secondary-color fs-6">كلمة المرور</label>
                        <span className='position-absolute pass-icon'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-lock" viewBox="0 0 16 16">
                            <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2m3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2M5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1"/>
                        </svg>
                        </span>
                        <input type={showNewPassword ? 'text' : 'password'} className={`form-control pe-5 secondary-color ${formErrors.inputPasswordErr?"inputErr":""}`} id="RegInputPassword" name="inputPassword" aria-describedby="inputpassword" value={form.inputPassword} onChange={(e) => handelFormChange(e)} onKeyDown={(e) =>handleEnglishKeyPress(e)}/>
                        <button type='button' className='position-absolute pass-btn border-0' onClick={toggleNewShowPassword}>
                            {showNewPassword ? 
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"/>
                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/>
                                </svg>
                            </span>
                            :
                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-slash" viewBox="0 0 16 16">
                                    <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z"/>
                                    <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829"/>
                                    <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z"/>
                                </svg>
                            </span>
                            }
                        </button>
                        <div className="pt-1 text-danger" id='inputpassword'>
                            <small>
                                {formErrors.inputPasswordErr}
                            </small>
                        </div>
                        </div>

                        <div className="mt-3 mb-3 position-relative">
                            <label htmlFor="inputPasswordConfirm" className="form-label secondary-color fs-6">تأكيد كلمة المرور</label>
                            <span className='position-absolute pass-icon'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-lock" viewBox="0 0 16 16">
                                <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2m3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2M5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1"/>
                            </svg>
                            </span>
                            <input type={showConfirmPassword ? 'text' : 'password'} className={`form-control pe-5 secondary-color ${formErrors.inputPasswordConfirmErr?"inputErr":""}`} id="ReginputPasswordConfirm" name="inputPasswordConfirm" aria-describedby="inputPasswordConfirm" value={form.inputPasswordConfirm} onChange={(e) => handelFormChange(e)}/>
                            <button type='button' className='position-absolute pass-btn border-0' onClick={toggleConfirmShowPassword}>
                                {showConfirmPassword ? 
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z"/>
                                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"/>
                                    </svg>
                                </span>
                                :
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-slash" viewBox="0 0 16 16">
                                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z"/>
                                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829"/>
                                        <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z"/>
                                    </svg>
                                </span>
                                }
                            </button>
                            <div className="pt-1 text-danger" id='inputPasswordConfirm'>
                                <small>
                                    {formErrors.inputPasswordConfirmErr}
                                </small>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center">
                        <button type="submit" className="modalBtn btn rounded-pill text-white px-5 my-3"
                        disabled={formErrors.inputPasswordErr ||
                            formErrors.inputPasswordConfirmErr || disabledBtn}>تغيير كلمة المرور</button>
                        </div>
                    </form>
                </div>
            </Modal.Body>
        </Modal>

    </section>
  )
}

