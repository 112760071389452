import React from 'react'
import './ContactSection.scss'

import star from '../../assets/images/star.webp'

export default function ContactSection() {
  return (
    <section className='ContactSection'>
        <div className="contact-title" id="contact-title">
            <div className="container">
                <div className="d-flex justify-content-center position-relative">
                    <img src={star} alt="star" className="contact-star position-absolute"/>
                    <span className="fs-1 secondary-color fw-bold ">تواصل معنا</span>
                </div>
            </div>
        </div>
        <div className="contact-map w-100">
            <div className='container'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13917.672330526204!2d47.706414473936725!3d29.299409506520803!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd4511c3cde5196d0!2zMjnCsDE3JzU4LjkiTiA0N8KwNDInMjMuMSJF!5e0!3m2!1sar!2sus!4v1657643863602!5m2!1sar!2sus" width="100%" height="400" style={{border:"0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" title="location"></iframe>
            </div>
        </div>
        <div className='contact-txt'>
            <div className='container'>
                <div className="row g-0 m-0">
                    <div className="col-12 col-lg-4">
                        <div className="contact-bg-1 d-flex flex-column text-center">
                            <span className='fs-5 mb-2 txt-color'>تواصل معنا</span>
                            <span className='secondary-color fs-3 fw-bold mb-2'>الهاتف</span>
                            <span className='fs-5 txt-color'>55576363</span>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className="contact-bg-2 d-flex flex-column text-center">
                            <span className='fs-5 mb-2 txt-color'>راسلنا</span>
                            <span className='secondary-color fs-3 fw-bold mb-2'>البريد الإليكتروني</span>
                            <span className='fs-5 txt-color'>info@ayaatacademy.org</span>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className="contact-bg-3 d-flex flex-column text-center">
                            <span className='fs-5 mb-2 txt-color'>شرفنا</span>
                            <span className='secondary-color fs-3 fw-bold mb-2'>حياكم</span>
                            <span className='fs-5 txt-color'>سعد العبدالله-ق8 - ش859 - م54 - بجوار دوار المرور</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
