import React from 'react'
import "./Courses.scss"

import HeaderSection from '../../Components/HeaderSection/HeaderSection'
import image from '../../assets/images/bdaia.jpg'
import star from '../../assets/images/star.webp'
import ContactSection from '../../Components/ContactSection/ContactSection'

export default function Courses() {
  return (
    <section className='courses' id='courses'>
      <HeaderSection image={image} flower={null}/>
      <div className='course'>
        <div className="course-title" id="course-title">
          <div className='container'>
            <div className="d-flex justify-content-center position-relative">
                <img src={star} alt="star" className="course-star position-absolute"/>
                <span className="fs-1 secondary-color fw-bold ">بداية المفسر</span>
            </div>
          </div>
        </div>
        <div className='course-content'>
          <div className='container'>
            <div className='row m-0 g-0'>
              <div className='col-12 col-lg-6'>
                <div className='content-txt d-flex justify-content-center py-5'>
                    <span className='w-75 fs-3 text-center'>السلام عليكم ورحمة الله وبركاته
                      طيب الله أوقات الجميع
                      نرحب بالإخوة والأخوات الراغبين بالاشتراك معنا في
                      برنامج بداية المفسر – الدفعة السابعة
                      وللاطلاع على تفاصيل البرنامج نرجو مطالعة الدليل التعريفي أدناه
                      سائلين من المولى الكريم العون والتوفيق والسداد للجميع</span>
                </div>
              </div>
              <div className='col-12 col-lg-6'>
                <div className='video d-flex justify-content-center w-100'>
                    <iframe className='border border-0 rounded-3 w-100' height="400" src="https://www.youtube.com/embed/q2b4sPZf7zI?si=J0hATA6TOhoOO6zX" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='course-txt'>
          <div className='container'>
            <div className="row g-0 m-0">
              <div className="col-12 col-lg-4">
                  <div className="course-bg-1 d-flex flex-column text-center">
                      <span className='fs-5 mb-2'>حالة الالتحاق</span>
                      <span className='secondary-color fs-3 fw-bold mb-2'>غير ملتحق</span>
                  </div>
              </div>
              <div className="col-12 col-lg-4">
                  <div className="course-bg-2 d-flex flex-column text-center">
                      <span className='fs-5 mb-2'>السعر</span>
                      <span className='secondary-color fs-3 fw-bold mb-2'>مجاني</span>
                  </div>
              </div>
              <div className="col-12 col-lg-4">
                  <div className="course-bg-3 d-flex flex-column text-center">
                      <span className='fs-5 mb-2'>البدء</span>
                      <span className='secondary-color fs-3 fw-bold mb-2'>حياكم</span>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='course-pdf pt-5'>
        <div className='container'>
          <div className='prog-pdf-titel mt-2 mb-4'>
            <span className='fs-2 fw-bold'>الدليل التعريفي لبرنامج بداية المفسر</span>
          </div>
          <div className='prog-pdf-view'>
            <object data="https://ayaatacademy.org/wp-content/uploads/2023/01/الدليل-التعريفي-لبرنامج-بداية-المفسر.pdf" type="application/pdf" style={{width:'100%',height:'800px'}} aria-label="تضمين الدليل-التعريفي-لبرنامج-بداية-المفسر."></object>
          </div>
        </div>
      </div>
      <ContactSection/>
    </section>
  )
}
