import React, { useEffect, useState } from 'react'
import "./Navbar.scss"
import { Link } from 'react-router-dom';
import Logo from '../../assets/images/nav-logo.jpg'
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { axiosInstance } from "../../network/axios";


export default function Navbar({handleShow}) {
    const dispatch = useDispatch();
    const [navbar, setNavbar] = useState(false);
    const [profile, setProfile] = useState(false);
    let auth  = useSelector(state => state.auth);
    let programmes  = useSelector(state => state.programmes.programmesList);

    const handleCheckUser = async ()=>{
        if(auth !== null){
            try{
                let res = await axiosInstance.get(`/user/user`, {
                    headers: {
                    authorization:auth.token,
                    },
                })
                if (res.data && res.data.Active && res.data.ActivatedEmail && res.data.Approval && !res.data.SoftDelete){
                    dispatch({
                    type: "GET_USER",
                    payload: res.data,
                    });
                    setProfile(true)
                }
            } catch (err) {console.log(err)}
        }else{
            setProfile(false)
        }
    }

    const handleProgrammesList= async()=>{
        try{
            let res = await axiosInstance.get(`/programme/programmes`)
            if(res.data){
                dispatch({
                    type: "GET_PROGRAMMES_LIST",
                    payload: res.data,
                });
            }
        } catch (err) {console.log(err)}
    }

    useEffect(()=>{
        handleProgrammesList()
    },[])

    useEffect(()=>{
        handleCheckUser()
    },[auth])

    const handleNavbar = () =>{
        if(window.scrollY >= 100){
            setNavbar(true)
        }else{
            setNavbar(false)
        }
    }
    window.addEventListener('scroll',handleNavbar);

    const handleLinkClick=()=>{
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
    }

    const logout = () => {
        dispatch({
            type: "LOGOUT",
            payload: null
        })
        window.localStorage.removeItem("auth")
    }

    return (
        <section className={`nav-bar `} id="nav-bar">
            <nav className={`navbar fixed-top navbar-light navbar-expand-lg ${navbar?'chnageColor':''}`}>
                <div className={`p-0 w-100`}>
                    <button className="navbar-toggler w-100 border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse " id="navbarNavDropdown">
                        <ul className='navbar-nav w-100 justify-content-center' >
                            <Link className="nav-logo position-relative ms-auto" to={`/`} onClick={handleLinkClick}>
                                <img src={Logo} alt='Logo'/>
                            </Link>
                            <li className="nav-item ms-3">
                                <Link className="nav-link fs-5 fw-bold" to={`/`} smooth="true"  onClick={handleLinkClick}>الرئيسية</Link>
                            </li>
                            <li className="nav-item ms-3 dropdown"> 
                                <Link to='' className={`nav-link dropdown-toggle fw-bold border-0 dropdown-right fs-5`} data-bs-toggle="dropdown" role="button" aria-expanded="false">
                                    البرامج
                                </Link>
                                <ul className={`dropdown-menu`}>
                                    {programmes.map((programme)=>(
                                        <li key={programme.ID}><Link to={`/programmes/${programme.ID}`} className={`dropdown-item nav-link fw-bold text-end`} onClick={handleLinkClick}>{programme.Title}</Link></li>
                                    ))}
                                </ul>
                            </li>
                            <li className="nav-item ms-3 dropdown">
                                <Link to='' className={`nav-link dropdown-toggle fw-bold border-0 dropdown-right fs-5`} data-bs-toggle="dropdown" role="button" aria-expanded="false">
                                    الدورات
                                </Link>
                                <ul className={`dropdown-menu`}>
                                <li><Link to={`/courses/${1}`} className={`dropdown-item nav-link fw-bold text-end`} onClick={handleLinkClick}>الدورة 1</Link></li>
                                </ul>
                            </li>
                            <li className="nav-item ms-3">
                                <Link className="nav-link fw-bold fs-5" to={`/about`} smooth="true">عن الاكاديمية</Link>
                            </li>
                            <li className="nav-item ms-3">
                                <Link className="nav-link fw-bold fs-5" to={`/contact`} smooth="true" onClick={handleLinkClick}>اتصل بنا</Link>
                            </li>
                            {profile === false ?
                                (<li className="nav-item">
                                    <button className="btn login-btn border-0" type="button" onClick={handleShow}>
                                        <span className='fs-5 fw-bold'>تسجيل الدخول</span>
                                    </button>
                                </li>)
                            :
                                <li className="nav-item ms-3 dropdown">
                                    <Link to='' className={`nav-link dropdown-toggle fw-bold border-0 dropdown-right fs-5`} data-bs-toggle="dropdown" role="button" aria-expanded="false">
                                        حسابك
                                    </Link>
                                    <ul className={`dropdown-menu`}>
                                    <li><Link to={`/profile`} className={`dropdown-item nav-link fw-bold text-end`} onClick={handleLinkClick}>الملف الشخصي</Link></li>
                                    <li><Link to={`/`} className={`dropdown-item nav-link fw-bold text-end`} onClick={logout}>تسجيل خروج</Link></li>
                                    </ul>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
            </nav>
        </section>
    )
}
