const INITIAL_STATE = {
    programmesList : [],
    programme:{}
}

export default function programmes(state = INITIAL_STATE,action) {
    switch (action.type) {
      case "GET_PROGRAMMES_LIST":
        return {
            ...state,
            programmesList : action.payload 
        };
      case "GET_PROGRAMME":
        return {
            ...state,
            programme : action.payload
        };
      default:
        return state;
    }
  }