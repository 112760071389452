import React from 'react'
import "./Contact.scss";

import HeaderSection from '../../Components/HeaderSection/HeaderSection'
import image from '../../assets/images/hero.jpg'
import star from '../../assets/images/star.webp'
import ContactSection from '../../Components/ContactSection/ContactSection'
import ContactForm from '../../Components/ContactForm/ContactForm'

export default function Contact() {
  return (
    <section className='contact'  id='contact'>
      <HeaderSection image={image} flower={null}/>

      <div className='cont-title'>
        <div className="container">
          <div className="d-flex justify-content-center position-relative">
              <img src={star} alt="star" className="cont-star position-absolute"/>
              <span className="fs-1 secondary-color fw-bold ">راسلنا</span>
          </div>
        </div>
      </div>
      <div className='cont-content'>
        <div className='container'>
          <div className='w-75 mx-auto pb-5'>
            <ContactForm/>
          </div>
        </div>
      </div>

      <ContactSection/>
    </section>
  )
}
