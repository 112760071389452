import React, { useState } from 'react'
import './ActivatedModal.scss'
import Modal from 'react-bootstrap/Modal';

import { axiosInstance } from "../../network/axios";
import { useEffect } from 'react';
import { useHistory } from "react-router-dom";

export default function ActivatedModal({showActivatedModal, setShowActivatedModal, params}) {
    const history = useHistory();
    const [message,setMessage]=useState()

    const hideActivatedModal =()=>{
        setShowActivatedModal(false)
        history.push('/')
    }

    useEffect(()=>{ handleActivated() },[])

    const handleActivated = async()=>{
        try{
            let res = await axiosInstance.patch("/user/activatedemail", {
                ID : params.id,
                UniqueString : params.uniqueString
            })
            if(res.data.status !== 'FAILED'){
                setMessage(res.data.message);
                setTimeout(async()=>{
                    setShowActivatedModal(false)
                    history.push('/')
                },3000)
            }else{
                setMessage(res.data.message);
            }
          }catch(err){
            console.log(err)
          }
    }
    
  return (
    <section>
        <Modal show={showActivatedModal} onHide={hideActivatedModal} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className='modalContent'>
                <Modal.Header closeButton></Modal.Header>
                <div className="modal-body d-flex flex-column align-items-center">
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="85"
                    height="85"
                    fill="currentColor"
                    className="bi bi-check-circle-fill animated swing"
                    viewBox="0 0 16 16"
                    >
                        <path
                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                        />
                    </svg>
                    <span className='title fs-3 fw-bold secondary-color mt-3'>{message}</span>
                </div>
            </Modal.Body>
        </Modal>
    </section>
  )
}

