import React from 'react'
import Modal from 'react-bootstrap/Modal';

export default function MessageModal({showCheckModal,setShowCheckModal}) {
    const handleClose =()=>{
        setShowCheckModal({
            open:false,
            message:""
        })
    }
  return (
    <section className='login-modal' id='login-modal'>
        <Modal show={showCheckModal.open} onHide={handleClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Body className='modalContent'>
                <Modal.Header closeButton></Modal.Header>
                <div className="modal-body d-flex flex-column align-items-center">
                    <span className='fs-1 fw-bold secondary-color m-auto'>
                        {showCheckModal.message}
                    </span>
                </div>
            </Modal.Body>
        </Modal>

    </section>
  )
}

